/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;600&display=swap');

/* Main footer styling */
.footer {
  /* background: linear-gradient(135deg,rgba(100, 211, 60, 0.5), #90eb63 100%);  */
  /* background: rgba(100, 211, 60, 0.5); Light green background with transparency */
  background: #90eb63;
  color: black;
  padding: 0px 10px 5px 10px;
  text-align: center;
  position: relative;
  flex-shrink: 0;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  margin: 5px 20px;
  min-width: 200px;
}

.footer-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: black;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  /* Uppercase for headings */
  border-bottom: 2px solid #4caf50;
  /* Underline for headings */
  padding-bottom: 10px;
}

.footer-section p,
.footer-section ul {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
}

.footer-section ul li a:hover {
  color: #111;
  /* Lighter green on hover */
  transform: translateX(5px);
  /* Slight shift on hover */
}

.column-icons {
  flex-direction: row;
  justify-content: space-evenly;
}

.social-icons a {
  font-size: 24px;
  color: #4a4a4a;
  margin-right: 15px;
  transition: color 0.3s, transform 0.3s;
}

.social-icons a:hover {
  color: #111;
  /* Lighter green on hover */
  transform: scale(1.1);
  /* Slight scale on hover */
}

.whatsapp-link {
  text-decoration: none;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.whatsapp-link:hover {
  color: green;
  /* WhatsApp green */
}

.whatsapp-text {
  margin-left: 10px;
  color: #4a4a4a;
}

.whatsapp-text:hover {
  color: green;
  /* WhatsApp green */
}

.linkedin-link:hover {
  color: #0A66C2;
  /* LinkedIn blue */
}

.facebook-link:hover {
  color: #1877F2;
  /* Facebook blue */
}

.email-link:hover {
  color: #0056b3;
  /* Darker blue on hover */
}

.footer-bottom {
  /* margin-top: 10px; */
  padding-top: 5px;
  border-top: 1px solid #4caf50;
  font-size: 14px;
  color: #4a4a4a;
}

.footer-bottom p {
  margin: 5px 0;
}

.footer-bottom a {
  color: #4a4a4a;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-bottom a:hover {
  color: #000;
  /* Lighter green on hover */
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin: 20px 0;
  }
}