.App {
  font-family: Arial, sans-serif;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  background: linear-gradient(to bottom, darkseagreen, #f4f4f4);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}








.center-placeholder {
  position: fixed;
  /* Ensures the div covers the entire screen */
  top: 0;
  left: 0;
  width: 100vw;
  /* Full width of the viewport */
  height: 100vh;
  /* Full height of the viewport */
  display: flex;
  justify-content: center;
  /* Horizontally center the image */
  align-items: center;
  /* Vertically center the image */
  background-color: #69ff33a1;
  /* White background */
  z-index: 999;
  /* Ensures it is on top of other content */
}

.center-placeholder img {
  opacity: 0.3;
  /* Adjust this for transparency */
  max-width: 50%;
  /* Make sure the logo fits well */
  max-height: 50%;
  /* Adjust size to fit well */
}





.no-activity {
  text-align: center;
  padding: 5rem;
  padding-bottom: 5rem;
  font-weight: 600;
}







::-webkit-scrollbar {
  display: none;
}