.container {
    width: 100%;
  }
  
  /* Hide scrollbars */
  .container::-webkit-scrollbar {
    display: none;
  }
  
  .container {
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
  .topnav {
    display: flex;
    align-items: center;
    /* background-color: #90eb63; Previous green color */
    background-color: #90eb63; /* Previous green color */
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 1000;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .logo {
    flex-grow: 1;
  }
  
  .company_text {
    font-size: 2rem;
    font-family: Cambria,Cochin,Georgia,Times,Times New Roman,serif;
    color: #111;
    margin-left: 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
  }
  
  .bars {
    display: none;
    cursor: pointer;
    font-size: 2.5rem; /* Adjusted for a cleaner look */
    color: #333;
    margin-left: 1rem;
    font-weight: 900;
  }
  
  .menu {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1rem;
    text-wrap: nowrap;
    position: relative; /* Ensure proper positioning */
  }
  
  .menu.open {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute; /* Position below .topnav */
    top: 100%; /* Adjust to be right below the .topnav */
    left: 0;
    background-color: #90eb63; /* Previous green color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 0.5rem; /* Ensure spacing from topnav */
    box-sizing: border-box;
    align-items: stretch;
  }
  
  .link {
    color: #333;
    padding: 0.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 500;
    /* transition: background-color 0.3s, color 0.3s; */
  }
  
  .link_text {
    font-size: 0.9rem; /* Smaller font size */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .link_text.open {
    font-size: 1.2rem; /* Smaller font size */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-left: 56px;
  }
  
  /* .active {
    background-color: rgba(105, 255, 51, .5); 
    color: #000; 
  } */

  .active {
    position: relative;
    color: #000; /* Adjust the text color if needed */
  }
  
  .active::after {
    content: "";
    position: absolute;
    bottom: -5px; /* Position the underline 5px below the text */
    left: 0;
    width: 100%;
    height: 5px; /* Height of the underline */
    background-color: #5adb2ba1; /* Adjusted green color for the underline */
    border-radius: 2px; /* Optionally round the corners of the underline */
  }
  
  
  .link:hover {
    /* background-color: #5fdd20; Adjusted green color */
    color: #050; /* White text */
  }
 
  @media (max-width: 768px) {
    .menu {
      display: none;
    }

    .company_text {
      font-size: 2rem;
    }

    /* .active {
      position: unset;
      color: #000; /* Adjust the text color if needed 
    } */

    .active{
      /* content: "";
      position: absolute;
      bottom: -5px; /* Position the underline 5px below the text 
      left: 0;
      width: 100%;
      height: 5px; Height of the underline */
      background-color: #5adb2ba1; /* Adjusted green color for the underline */
      /* border-radius: 2px; Optionally round the corners of the underline */
    }
    .bars {
      display: block;
    }
  
    .menu.open {
      display: flex;
      flex-direction: column;
    }
  }

   
  @media (max-width: 678px) {
   

    .company_text {
      font-size: 1.6rem;
    }
  }
  